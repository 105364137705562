/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from "swiper";

// SwiperJS react components
import { Swiper, SwiperSlide } from "swiper/react";

// SwiperJS styles
import "swiper/swiper.min.css";
import "swiper/css/navigation";

// Pricing page components
import SliderHeader from "../components/Header";

// Images
import bg1 from "assets/images/bg2.jpg";
import bg2 from "assets/images/bg.jpg";
import bg3 from "assets/images/dg1.jpg";

function Header() {
  // install SwiperJS modules
  SwiperCore.use([Autoplay, Navigation]);

  return (
    <Swiper
      autoplay={{ delay: 5000 }}
      speed={800}
      spaceBetween={0}
      slidesPerView={1}
      navigation
      loop
    >
      <SwiperSlide>
        <SliderHeader
          image={bg1}
          label=""
          title="Your trusted partner in navigating East Africa."
          description="Dedicated to assisting foreigners and diaspora in finding the essentials they need upon arrival in Rwanda, Uganda, Tanzania, and Kenya."
          cards={[
            {
              variant: "gradient",
              color: "info",
              icon: "house",
              title: "Find a House/Car to Rent or Buy",
              description:
                "We help you find the perfect property, whether renting or buying, and arrange the ideal car for your stay. Professional drivers are available for added convenience.",
            },
            {
              variant: "contained",
              color: "info",
              icon: "landscape",
              title: "Purchase Land/Plots",
              description:
                "Looking to invest in land? We can help you find prime land or plots that meet your requirements, whether for residential or commercial purposes.",
            },
            {
              variant: "contained",
              color: "info",
              icon: "hail",
              title: "Hire a Maid",
              description:
                "You don't have to worry about who to help clean your house, shop, cook, etc. We have perfect maids for all those kind of works",
            },
          ]}
        />
      </SwiperSlide>
      <SwiperSlide>
        <SliderHeader
          image={bg2}
          title="Your trusted partner in navigating East Africa."
          description="Dedicated to assisting foreigners and diaspora in finding the essentials they need upon arrival in Rwanda, Uganda, Tanzania, and Kenya."
          cards={[
            {
              variant: "gradient",
              color: "primary",
              icon: "house",
              title: "Find a House/Car to Rent or Buy",
              description:
                "We help you find the perfect property, whether renting or buying, and arrange the ideal car for your stay. Professional drivers are available for added convenience.",
            },
            {
              variant: "contained",
              color: "primary",
              icon: "landscape",
              title: "Purchase Land/Plots",
              description:
                "Looking to invest in land? We can help you find prime land or plots that meet your requirements, whether for residential or commercial purposes.",
            },
            {
              variant: "contained",
              color: "primary",
              icon: "hail",
              title: "Hire a Maid",
              description:
                "You don't have to worry about who to help clean your house, shop, cook, etc. We have perfect maids for all those kind of works",
            },
          ]}
        />
      </SwiperSlide>
      <SwiperSlide>
        <SliderHeader
          image={bg3}
          label=""
          title="Your trusted partner in navigating East Africa."
          description="Dedicated to assisting foreigners and diaspora in finding the essentials they need upon arrival in Rwanda, Uganda, Tanzania, and Kenya."
          cards={[
            {
              variant: "gradient",
              color: "error",
              icon: "house",
              title: "Find a House/Car to Rent or Buy",
              description:
                "We help you find the perfect property, whether renting or buying, and arrange the ideal car for your stay. Professional drivers are available for added convenience.",
            },
            {
              variant: "contained",
              color: "error",
              icon: "landscape",
              title: "Purchase Land/Plots",
              description:
                "Looking to invest in land? We can help you find prime land or plots that meet your requirements, whether for residential or commercial purposes.",
            },
            {
              variant: "gradient",
              color: "error",
              icon: "hail",
              title: "Hire a Maid",
              description:
                "You don't have to worry about who to help clean your house, shop, cook, etc. We have perfect maids for all those kind of works",
            },
          ]}
        />
      </SwiperSlide>
    </Swiper>
  );
}

export default Header;
