import Icon from "@mui/material/Icon";

// Pages
//import Pricing from "layouts/pages/landing-pages/Pricing";
import Home from "pages/Home";
import AboutUs from "pages/AboutUs";
import Booking from "pages/Booking";
import ContactUs from "pages/ContactUs";

const routes = [
  {
    name: "HOME",
    icon: <Icon>dashboard</Icon>,
    route: "/",
    component: <Home />,
  },
  {
    name: "ABOUT",
    icon: <Icon>dashboard</Icon>,
    route: "/about",
    component: <AboutUs />,
  },
  {
    name: "CONTACT",
    icon: <Icon>dashboard</Icon>,
    route: "/contact",
    component: <ContactUs />,
  },
  {
    route: "/booking",
    component: <Booking />,
  },
];

export default routes;
