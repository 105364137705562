/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import getStartedImg from "../../../assets/images/nastuh.jpg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="holiday_village"
                    title="Find a House to Rent or Buy"
                    description="Submit a request, and we'll help you find the perfect rental or home to purchase, tailored to your needs in East Africa."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="electric_car"
                    title="Rent or Buy a Car with Driver"
                    description="Make a booking, and we'll arrange a car rental or purchase that suits your transportation needs. Professional drivers are available to ensure a smooth journey."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="landscape"
                    title="Purchase Land/Plots"
                    description="Looking to invest in land? Submit a request, and we'll assist you in finding the ideal plot or land for your requirements."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="local_grocery_store"
                    title="Hire a Maid"
                    description="Request our service, and we'll find you a reliable, multilingual maid to assist you during your stay."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={getStartedImg}
              title="Get the Best Out of Your Stay"
              description="Discover how our booking services can make your stay in East Africa seamless and enjoyable. From finding the perfect rental home to arranging car rentals and hiring maids, we handle it all."
              action={{
                type: "internal",
                route: "/booking",
                color: "primary",
                label: "Get Started here",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
