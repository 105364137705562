import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// @mui material components
// import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKAlert from "components/MKAlert";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Pricing page sections
import Header from "./sections/Header";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Material Kit 2 PRO React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

//--- Images ---
import bg1 from "assets/images/examples/content-0.jpg";
import bg2 from "assets/images/examples/content-1.jpg";
import bg3 from "assets/images/examples/content-2.jpg";
import bg4 from "assets/images/examples/content-3.jpg";
import bg5 from "assets/images/examples/content-4.jpg";
import bg6 from "assets/images/examples/content-5.jpg";
import contactImage from "assets/images/examples/content-6.jpg";

function Home() {
  // server from env
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  //vars declaration
  const [alertMsg, setAlertMsg] = useState("");
  const [names, setNames] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  //--- function to handle submit ---
  const handleContact = (e) => {
    e.preventDefault();
    const data = JSON.stringify({
      names: names,
      email: email,
      phone: phone,
      message: message,
    });

    axios
      .post(SERVER_URL + "/contact/post/", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data === "Contacted") {
          setAlertMsg("Thank you for contacting us, We'll Get Back to you");
        } else {
          alert("BYANZE, Subscription Yawe");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/booking",
          label: "BOOK NOW",
        }}
        transparent
        light
      />
      <Header />
      <MKBox component="section" py={6}>
        <Container>
          <Grid
            container
            item
            justifyContent="center"
            xs={12}
            lg={6}
            sx={{ mx: "auto", pb: 3, textAlign: "center" }}
          >
            <MKTypography variant="h4" color="info" textGradient>
              Why Us?
            </MKTypography>
            <MKTypography variant="h2" my={1}>
              No more struggles than before
            </MKTypography>
            <MKTypography variant="body1" color="text">
              Trust us, we have been there too. we know how awkawrdlt it feels to be embarrassed due
              to lack of very basic traveling needs
            </MKTypography>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            <Grid item xs={12} md={6} lg={4}>
              <DefaultInfoCard
                icon="groups"
                title="Meet Our Team"
                description="Our dedicated team is here to assist you with all your needs, ensuring a smooth and enjoyable stay in East Africa."
                direction="center"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DefaultInfoCard
                icon="support_agent"
                title="24/7 Support"
                description="We offer round-the-clock support to address any concerns or questions you may have during your stay."
                direction="center"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} sx={{ mx: "auto" }}>
              <DefaultInfoCard
                icon="update"
                title="Flexible Services"
                description="Our services are tailored to your needs with the flexibility to make changes as required to ensure your satisfaction."
                direction="center"
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="section" py={1}>
        <Container>
          <Grid
            container
            item
            xs={8}
            flexDirection="column"
            alignItems="center"
            mx="auto"
            textAlign="center"
            mb={6}
          ></Grid>
          <Grid container spacing={3} minHeight="60vh">
            <Grid item xs={5} sm={4}>
              <MKBox
                width="100%"
                height="100%"
                borderRadius="lg"
                shadow="md"
                sx={{
                  backgroundImage: `url(${bg1})`,
                  backgroundSize: "cover",
                }}
              />
            </Grid>
            <Grid item xs={7} sm={3}>
              <MKBox
                width="100%"
                height="100%"
                borderRadius="lg"
                shadow="md"
                sx={{
                  backgroundImage: `url(${bg2})`,
                  backgroundSize: "cover",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <MKBox
                width="100%"
                height="100%"
                borderRadius="lg"
                shadow="md"
                sx={{
                  backgroundImage: `url(${bg3})`,
                  backgroundSize: "cover",
                }}
              />
            </Grid>
            <Grid item xs={7} sm={3}>
              <MKBox
                width="100%"
                height="100%"
                borderRadius="lg"
                shadow="md"
                sx={{
                  backgroundImage: `url(${bg4})`,
                  backgroundSize: "cover",
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <MKBox
                width="100%"
                height="100%"
                borderRadius="lg"
                shadow="md"
                sx={{
                  backgroundImage: `url(${bg5})`,
                  backgroundSize: "cover",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <MKBox
                width="100%"
                height="100%"
                borderRadius="lg"
                shadow="md"
                sx={{
                  backgroundImage: `url(${bg6})`,
                  backgroundSize: "cover",
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={2}
        p={6}
        bgColor="info"
        variant="gradient"
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={8} lg={5}>
            <MKTypography variant="h5" color="white" fontWeight="bold">
              Stop struggling, Stop wondering, We here for you, to help you have a smooth a stay
              rather than rough bizarre vacations.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: "auto" }}>
            <MKBox width="12rem" ml="auto">
              <Link to="/booking">
                <MKButton variant="gradient" color="primary" fullWidth sx={{ boxShadow: "none" }}>
                  Book now
                </MKButton>
              </Link>
            </MKBox>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox
        component="section"
        display="grid"
        position="relative"
        minHeight="90vh"
        borderRadius="xl"
        mr={{ xs: 0, lg: 8 }}
        mb={{ xs: 0, lg: -2 }}
        mt={{ xs: 0, lg: 5 }}
        sx={{ overflow: { xs: "hidden", lg: "visible" }, placeItems: "center" }}
      >
        <MKBox
          component="img"
          alt="lake house"
          src={contactImage}
          width={{ xs: "100%", lg: "50%" }}
          height={{ xs: "100%", lg: "100%" }}
          position="absolute"
          right={0}
          bottom={{ xs: "-25%", lg: "unset" }}
          top={{ xs: 0, lg: "unset" }}
          sx={{
            objectFit: "cover",
            borderTopLeftRadius: ({ borders: { borderRadius } }) => ({
              xs: 0,
              lg: borderRadius.lg,
            }),
          }}
        />
        <Container>
          <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ mt: { xs: 0, lg: 12 } }}>
            <Grid item xs={12} lg={7} justifyContent="center" flexDirection="column">
              <MKBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                shadow="lg"
                borderRadius="xl"
                sx={{
                  backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                    rgba(white.main, 0.8),
                  backdropFilter: "saturate(200%) blur(30px)",
                  px: { xs: 3, sm: 6 },
                  py: { xs: 3, sm: 8 },
                  mb: { xs: 0, lg: 8 },
                  mt: { xs: 0, lg: -6 },
                }}
              >
                <MKTypography variant="h3" textAlign="center" mb={2}>
                  Contact us
                </MKTypography>
                <MKBox component="form" role="form" onSubmit={handleContact} autoComplete="off">
                  {alertMsg.length > 0 && <MKAlert>{alertMsg}</MKAlert>}
                  <MKBox py={3} px={{ xs: 3, md: 12, lg: 6, xl: 12 }}>
                    <Grid container spacing={3} sx={{ mb: 3 }}>
                      <Grid item xs={12} md={6} lg={12} xl={6}>
                        <MKInput
                          variant="standard"
                          placeholder="Names here..."
                          label="Name"
                          onChange={(e) => setNames(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={12} xl={6}>
                        <MKInput
                          variant="standard"
                          placeholder="Tell here..."
                          label="Telephone"
                          onChange={(e) => setPhone(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ my: 1 }}>
                        <MKInput
                          variant="standard"
                          type="email"
                          placeholder="Email here..."
                          label="Email Address"
                          onChange={(e) => setEmail(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MKInput
                          variant="standard"
                          label="Your Message"
                          onChange={(e) => setMessage(e.target.value)}
                          rows={4}
                          InputLabelProps={{ shrink: true }}
                          multiline
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <MKButton type="submit" variant="gradient" color="primary" fullWidth>
                          Send Message
                        </MKButton>
                      </Grid>
                    </Grid>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
