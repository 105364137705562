import { useState } from "react";
import axios from "axios";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import MKAlert from "components/MKAlert";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKDatePicker from "components/MKDatePicker";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/examples/blog-9-4.jpg";
import bgImage1 from "assets/images/examples/content-2.jpg";

function Booking() {
  // server from env
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  //vars declaration
  const [alertMsg, setAlertMsg] = useState("");
  const [names, setNames] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [message, setMessage] = useState("");
  const [languages, setLanguages] = useState([]);
  const [services, setServices] = useState([]);

  //--- function to handle submit ---
  const handleBooking = (e) => {
    e.preventDefault();

    const data = JSON.stringify({
      names: names,
      email: email,
      phone: phone,
      startDate: startDate,
      endDate: endDate,
      language: languages,
      service: services,
      message: message,
    });

    axios
      .post(`${SERVER_URL}/booking/add/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data === "Inserted") {
          setAlertMsg("Received!, We'll Get Back to You");
        } else {
          alert("BYANZE, Booking Yawe");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <>
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/booking",
            label: "BOOK NOW",
            color: "info",
          }}
          transparent
          light
        />
        <MKBox
          minHeight="50vh"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MKTypography
                variant="h2"
                color="white"
                mb={2}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Discover Comfort and Convenience
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            overflow: "hidden",
          }}
        >
          <MKBox component="section" py={{ lg: 6 }}>
            <Container>
              <Grid container item px={6}>
                <MKBox
                  width="100%"
                  bgColor="white"
                  borderRadius="xl"
                  shadow="xl"
                  mb={6}
                  sx={{ overflow: "hidden" }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={7}>
                      <MKBox component="form" p={2} role="form" onSubmit={handleBooking}>
                        <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                          {alertMsg.length > 0 && <MKAlert>{alertMsg}</MKAlert>}
                          <MKTypography variant="h2" mb={1}>
                            Book a Service
                          </MKTypography>
                          <MKTypography variant="body2" color="text" mb={2}>
                            Book with Ease, Tailored Services for Every Traveler &ndash; From
                            Arrival to Departure, We&apos;ve Got You Covered
                          </MKTypography>
                        </MKBox>
                        <MKBox pt={0.5} pb={3} px={3}>
                          <Grid container>
                            <Grid item xs={12} pr={1} mb={3}>
                              <MKInput
                                onChange={(e) => setNames(e.target.value)}
                                variant="outlined"
                                label="Full Name"
                                placeholder="Names here..."
                                size="large"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} pr={1} mb={3}>
                              <MKInput
                                onChange={(e) => setPhone(e.target.value)}
                                variant="outlined"
                                size="large"
                                label="Telephone"
                                placeholder="Tel here..."
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} pr={1} mb={3}>
                              <MKInput
                                onChange={(e) => setEmail(e.target.value)}
                                variant="outlined"
                                label="Email"
                                placeholder="Email here..."
                                size="large"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} pr={1} mb={3}>
                              <MKTypography
                                display="block"
                                variant="button"
                                fontWeight="regular"
                                color="text"
                                mb={1}
                              >
                                Select Service
                              </MKTypography>
                              <Autocomplete
                                multiple
                                options={["House Rent", "Car Rent", "Housemaid", "Other"]}
                                getOptionLabel={(option) => option}
                                onChange={(event, value) => setServices(value)}
                                renderInput={(params) => <MKInput {...params} variant="outlined" />}
                              />
                            </Grid>
                            <Grid item xs={12} pr={1} mb={3}>
                              <MKTypography
                                display="block"
                                variant="button"
                                fontWeight="regular"
                                color="text"
                                mb={1}
                              >
                                Prefered Language
                              </MKTypography>
                              <Autocomplete
                                multiple
                                options={[
                                  "English",
                                  "French",
                                  "Luganda",
                                  "Swahili",
                                  "Arabic",
                                  "Kinyarwanda",
                                  "Other",
                                ]}
                                getOptionLabel={(option) => option}
                                onChange={(event, value) => setLanguages(value)}
                                renderInput={(params) => <MKInput {...params} variant="outlined" />}
                              />
                            </Grid>
                            <Grid item xs={12} pr={1} mb={3}>
                              <MKTypography
                                display="block"
                                variant="button"
                                fontWeight="regular"
                                color="text"
                                mb={1}
                              >
                                Select Start Dates
                              </MKTypography>
                              <MKDatePicker
                                variant="standard"
                                input={{ placeholder: "Service Start Date" }}
                                fullWidth
                                onChange={(date) => setStartDate(date[0])}
                              />
                            </Grid>
                            <Grid item xs={12} pr={1} mb={3}>
                              <MKTypography
                                display="block"
                                variant="button"
                                fontWeight="regular"
                                color="text"
                                mb={1}
                              >
                                Select End Dates
                              </MKTypography>
                              <MKDatePicker
                                variant="standard"
                                input={{ placeholder: "Service End Date" }}
                                fullWidth
                                onChange={(date) => setEndDate(date[0])}
                              />
                            </Grid>
                            <Grid item xs={12} pr={1} mb={3}>
                              <MKInput
                                onChange={(e) => setMessage(e.target.value)}
                                variant="outlined"
                                label="Other Comments"
                                placeholder="Comments here..."
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                multiline
                                rows={6}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            md={6}
                            justifyContent="flex-end"
                            textAlign="right"
                            ml="auto"
                          >
                            <MKButton variant="gradient" color="info" type="submit" fullWidth>
                              Book Now
                            </MKButton>
                          </Grid>
                        </MKBox>
                      </MKBox>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={5}
                      position="relative"
                      px={0}
                      sx={{
                        backgroundImage: ({
                          palette: { gradients },
                          functions: { rgba, linearGradient },
                        }) =>
                          `${linearGradient(
                            rgba(gradients.info.main, 0.8),
                            rgba(gradients.info.state, 0.8)
                          )}, url(${bgImage1})`,
                        backgroundSize: "cover",
                      }}
                    >
                      <MKBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                        height="100%"
                      >
                        <MKBox py={6} pl={6} pr={{ xs: 6, sm: 12 }} my="auto">
                          <MKTypography variant="h3" color="white" mb={1}>
                            Instant Call
                          </MKTypography>
                          <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                            You can reach to use via the following and our Team will get back to you
                            in an instant.
                          </MKTypography>
                          <MKBox display="flex" p={1}>
                            <MKTypography variant="button" color="white">
                              <i className="fas fa-phone" />
                            </MKTypography>
                            <MKTypography
                              component="span"
                              variant="button"
                              color="white"
                              opacity={0.8}
                              ml={2}
                              fontWeight="regular"
                            >
                              (+250) 788 533 300
                            </MKTypography>
                          </MKBox>
                          <MKBox display="flex" color="white" p={1}>
                            <MKTypography variant="button" color="white">
                              <i className="fas fa-envelope" />
                            </MKTypography>
                            <MKTypography
                              component="span"
                              variant="button"
                              color="white"
                              opacity={0.8}
                              ml={2}
                              fontWeight="regular"
                            >
                              hello@helpereverywhere.com helpereverywheremail@gmail.com
                            </MKTypography>
                          </MKBox>
                          <MKBox display="flex" color="white" p={1}>
                            <MKTypography variant="button" color="white">
                              <i className="fas fa-map-marker-alt" />
                            </MKTypography>
                            <MKTypography
                              component="span"
                              variant="button"
                              color="white"
                              opacity={0.8}
                              ml={2}
                              fontWeight="regular"
                            >
                              Rwanda Kigali , KG 314 ST
                            </MKTypography>
                          </MKBox>
                          <MKBox mt={3}>
                            <MKButton variant="text" color="white" size="large" iconOnly>
                              <i className="fab fa-facebook" style={{ fontSize: "1.25rem" }} />
                            </MKButton>
                            <MKButton variant="text" color="white" size="large" iconOnly>
                              <i className="fab fa-twitter" style={{ fontSize: "1.25rem" }} />
                            </MKButton>
                            <MKButton variant="text" color="white" size="large" iconOnly>
                              <i className="fab fa-instagram" style={{ fontSize: "1.25rem" }} />
                            </MKButton>
                          </MKBox>
                        </MKBox>
                      </MKBox>
                    </Grid>
                  </Grid>
                </MKBox>
              </Grid>
            </Container>
          </MKBox>
        </Card>
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </>
    </>
  );
}

export default Booking;
