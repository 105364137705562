import { useState } from "react";
import axios from "axios";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKAlert from "components/MKAlert";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Images
import macbook from "assets/images/NewletterBg.jpeg";

function Newsletter() {
  // server from env
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  //vars declaration
  const [email, setEmail] = useState("");
  const [alertMsg, setAlertMsg] = useState("");

  //--- function to handle submit ---
  const handleSubscribe = (e) => {
    e.preventDefault();
    const data = JSON.stringify({
      email: email,
    });

    axios
      .post(SERVER_URL + "/newsletter/subscribed/", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data === "Subscribed") {
          setAlertMsg("Thank you for subscribing");
        } else {
          alert("BYANZE, Subscription Yawe");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <MKBox component="form" pt={6} my={1} role="form" onSubmit={handleSubscribe}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            {alertMsg.length > 0 && <MKAlert>{alertMsg}</MKAlert>}
            <MKTypography variant="h4">Stay Updated with Helper Everywhere</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              Stay informed about the latest services and offers tailored to make your stay in East
              Africa as comfortable and convenient as possible.
            </MKTypography>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <MKInput
                  type="email"
                  label="Email Here..."
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <MKButton variant="gradient" color="primary" sx={{ height: "100%" }} type="submit">
                  Subscribe
                </MKButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Newsletter;
